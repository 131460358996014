import React from "react"

const TermsPolicy = ({cssClass}) => {
    return(
        <div className={cssClass}>
            <ol className={`${cssClass}_ol`}>
                <li data-level="1">
                    <h2 className="h">Introduction</h2>
                    <ol className="l2">
                        <li>This document sets out the terms and conditions upon which Squirrel Learning Limited (<strong>‘Squirrel’</strong>, <strong>‘we’</strong> or <strong>‘us’</strong>) provides the educational software applications marketed as “GoApps” including “GoRead” and “GoWrite” (the <strong>‘GoApps’</strong>) to schools and individual educational bodies (<strong>‘Schools’</strong>), and allows use by the Schools’ staff, its pupils and their parents/guardians (<strong>‘End Users’</strong>).</li>
                        <li>Purchase of the GoApps by Schools and use of the GoApps by End Users denotes acceptance of these terms and conditions.</li>
                        <li>Squirrel offers subscriptions which entitle End Users to use one or both GoApps (the <strong>‘User Subscriptions’</strong>). A contract is formed between the School and Squirrel, when:
                            <ol className="l3">
                                <li>The School has made a request to purchase User Subscriptions using the relevant online form; and</li>
                                <li>Squirrel has invoiced the School for that to indicate acceptance.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li data-level="2">
                    <h2 className="h">User Subscriptions</h2>
                    <ol className="l2">
                        <li>User Subscriptions are specific to an individual School, and cannot be shared by multiple schools within the same ownership, academy trust, federation etc. </li>
                        <li>Each User Subscription term is one year, commencing on the date of the purchase request in clause 1.3(a), subject to clause 6 below and unless otherwise agreed at Squirrel’s discretion (for example a short trial period).</li>
                        <li>This agreement shall, unless renewed pursuant to clause 5, or terminated early as provided in 14 or otherwise, terminate at the end of the subscription term.</li>
                    </ol>
                </li>
                <li data-level="3">
                    <h2 className="h">Payment</h2>
                    <ol className="l2">
                        <li>The User Subscription fees payable are displayed at the time of the purchase request on the GoApps websites go-read.co.uk and go-write.co.uk. A School and Squirrel may agree a trial period at a discounted rate, at Squirrel’s discretion.</li>
                        <li>Content and/or services beyond the standard User Subscription may be subject to additional fees also displayed on the Squirrel Website.</li>
                        <li>The School must make payment within 30 days.</li>
                        <li>If Squirrel has not received timely payment, it may disable the School's password, account and access to all or part of the GoApps, and those of the School’s End Users, while the invoice(s) concerned remain unpaid.</li>
                        <li>No delay in the exercise Squirrel’s rights in clause 3.4 above shall constitute a waiver of such rights.</li>
                        <li>All amounts and fees shall be payable in the currency stated on the invoice and are non-cancellable and non-refundable beyond 30 days after the invoice date (provided the School has used the GoApp(s)), except in the case that the GoApp(s) are faulty in line with applicable statutory rules.</li>
                    </ol>
                </li>
                <li data-level="4">
                    <h2 className="h">The Services</h2>
                    <ol className="l2">
                        <li>For the duration of the User Subscriptions, Squirrel shall provide access to and use of the GoApp(s) for which User Subscriptions were purchased, by means of <a href="https://go-read.co.uk">go-read.co.uk</a>  and <a href="https://go-write.co.uk">go-write.co.uk</a>, and/or by means of downloading an app onto a computer, mobile phone or other digital device.</li>
                        <li>Squirrel:
                            <ol className="l3">
                                <li>grants to the School a non-exclusive, non-transferable and non-sub-licensable right to permit the End Users to use the relevant GoApp solely for the School's educational purposes.</li>
                                <li>will make available the relevant online or downloadable software applications.</li>
                                <li>will make available media which sets out a more detailed description of the GoApps and the user instructions for the GoApps (the <strong>‘Documentation’</strong>).</li>
                                <li>provide the School with basic customer support services during normal business hours.</li>
                            </ol>
                        </li>
                        <li>Squirrel shall use commercially reasonable endeavours to make the GoApps available 24 hours a day, seven days a week, except for unscheduled maintenance performed outside normal business hours.</li>
                    </ol>
                </li>
                <li data-level="5">
                    <h2 className="h">Renewals</h2>
                    <ol className="l2">
                        <li>Squirrel shall send a written reminder to the School prior to the end of the active subscription term inviting renewal and noting any increased fees.</li>
                        <li>If the school submits a request to renew, Squirrel shall invoice the School for the fees payable in respect of the renewed User Subscription term (which may be increased from the prior year).</li>
                        <li>The rules for payment in clause 3 apply to renewals.</li>
                    </ol>
                </li>
                <li data-level="6">
                    <h2 className="h">Additional User Subscriptions</h2>
                    <ol className="l2">
                        <li>The School may, from time to time during any subscription term, purchase additional User Subscriptions by request.</li>
                        <li>Where this occurs partly into the subscription term for existing User Subscriptions:
                            <ol className="l3">
                                <li>The full annual User Subscriptions fee shall apply; but </li>
                                <li>The additional User Subscriptions shall terminate when the School’s existing User Subscriptions terminate, so that for future renewal years all of the School’s User Subscriptions will be aligned.</li>
                            </ol>
                        </li>
                        <li>The charge for additional User Subscriptions will be invoiced retrospectively when the School’s existing User Subscriptions terminate.</li>
                    </ol>
                </li>
                <li data-level="7">
                    <h2 className="h">Squirrel’s obligations</h2>
                    <ol className="l2">
                        <li>Squirrel undertakes that the GoApps services will be performed substantially in accordance with the Documentation and with reasonable skill and care.</li>
                        <li>Squirrel:
                            <ol className="l3">
                                <li>does not warrant that the End Users’ use of the GoApp(s) will be uninterrupted or error-free, or free of viruses or security vulnerabilities; and</li>
                                <li>is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet, and the School and End Users acknowledge that the GoApps may be subject to limitations, delays and other problems inherent in the use of such communications facilities.</li>
                            </ol>
                        </li>
                        <li>Squirrel shall follow its normal archiving procedures for School Data and End User Data (defined below) which is as follows: all data is backed up by a third party on a rolling basis of a period of no less than 2 days and no more than 30 days. In the event of any loss or damage to School Data or End User Data, the School's sole and exclusive remedy against Squirrel shall be for Squirrel to use reasonable commercial endeavours to restore the lost or damaged data from the latest back-up of such data.</li>
                    </ol>
                </li>
                <li data-level="8">
                    <h2 className="h">School's obligations</h2>
                    <ol className="l2">
                        <li>The School shall use reasonable endeavours to:
                            <ol className="l3">
                                <li>prevent any unauthorised access to, or use of, the GoApps and/or the Documentation and, in the event of any such unauthorised access or use, promptly notify Squirrel;</li>
                                <li>provide Squirrel with all necessary co-operation and access to such information as may be required by Squirrel in order to provide the GoApps, including but not limited to data inputted by the School or End Users security access information and configuration services (together comprising <strong>‘School Data’</strong>);</li>
                                <li>ensure that the End Users use the GoApps and the Documentation in accordance with the terms and conditions of this agreement (including but not limited to the sub-clauses that immediately follow);</li>
                                <li>ensure that their network, systems and network security are adequate; </li>
                                <li>not use rude or inappropriate avatar names;</li>
                                <li>not access, store, hack, distribute or transmit any material (including computer viruses) during the course of its use of the GoApps that is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or ethnically offensive, and Squirrel reserves the right to disable access to GoApps and to any material that breaches the provisions of this clause;</li>
                                <li>not, except as otherwise expressly agreed in writing, attempt to copy, plagiarise, modify, duplicate, attempt to decompile or do anything else which may infringe Squirrel’s copyright in the Software and/or Documentation, or that of any other End User (e.g. text they have created);</li>
                                <li>not use the Documents or GoApps for commercial purposes without first obtaining Squirrel’s prior written consent.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li data-level="9">
                    <h2 className="h">End Users’ Obligations</h2>
                    <ol className="l2">
                        <li>Clauses 8.1(d) to 8.1(h) apply equally to End Users.</li>
                        <li>Should any School or End User be in breach of clauses 8 or 9, or otherwise intentionally misuse the GoApps, Squirrel reserves its right to revoke their account(s) without notice or refund.</li>
                    </ol>
                </li>
                <li data-level="10">
                    <h2 className="h">Data</h2>
                    <ol className="l2">
                        <li>Squirrel’s Privacy Policy, as published from time to time on its website and the GoApps websites shall apply and by using the GoApps the School and End Users confirm agreement to the Privacy Policy.</li>
                        <li>Squirrel is not responsible for Schools’ legal obligations as regards data protection.</li>
                        <li>In purchasing User Subscriptions the School warrants that it is authorised to provide the School Data to Squirrel for processing in accordance with Squirrel’s Privacy Policy. </li>
                        <li>The parties are entitled to audit the other’s compliance with this clause on reasonable request once every 12 months.</li>
                    </ol>
                </li>
                <li data-level="11">
                    <h2 className="h">Proprietary rights</h2>
                    <ol className="l2">
                        <li>The parties acknowledge that Squirrel and/or its licensors own all intellectual property rights in the GoApps, including but not limited to all information, text (except School Data and End User Data), materials, multimedia, code, tools and results derived from the use of software and tools, Squirrel advertisements, names, logos and trade marks in the Documents and GoApps.</li>
                        <li>The School shall own all rights in the School Data that is not personal data and shall have sole responsibility for the legality, reliability, integrity, accuracy and quality of all such School Data.</li>
                        <li>The End Users shall own all rights in the data they input in the course of using the GoApps (<strong>‘End User Data’</strong>).</li>
                        <li>The School and End Users, in using the GoApps, grant to Squirrel a licence to use the School Data and End User Data for the purpose of:
                            <ol className="l3">
                                <li>delivering the GoApps service (including displaying End Users’ data to Schools);</li>
                                <li>undertaking data analysis (of any kind and for any purpose including commercial sale of analytics); </li>
                                <li>marketing, so long as the work is anonymised and large works are not used in their entirety; and</li>
                                <li>any other reasonable further use subject to the terms of Squirrel’s Privacy Policy.</li>
                            </ol>
                        </li>
                        <li>The licence in clause 11.4 above shall survive termination of this agreement.</li>
                        <li>Squirrel shall own all rights to any analytics generated in relation to the GoApps, including that generated from School Data or data provided by End Users.</li>
                    </ol>
                </li>
                <li data-level="12">
                    <h2 className="h">Limitation of liability</h2>
                    <ol className="l2">
                        <li>The School assumes sole responsibility for results obtained from the use of the GoApps by the School and End Users, for conclusions drawn from such use, and for the integrity and accuracy of the School Data and End User Data. </li>
                        <li>The GoApps and the Documentation are provided to the School on an "as is" basis.</li>
                        <li>Squirrel shall have:
                            <ol className="l3">
                                <li>no liability for any loss, theft or corruption of data or information, computer virus, reputational damage, pure economic loss or any special, indirect or consequential loss.</li>
                                <li>no liability to the School under this agreement if it is prevented from or delayed in performing its obligations under this agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control.</li>
                                <li>a total aggregate liability arising in connection with the performance of this agreement limited to 100 times the total subscription fees paid by the School during the 12 months preceding the date on which the claim arose.</li>
                            </ol>
                        </li>
                        <li>All warranties, representations, conditions and all other terms of any kind implied by statute or common law are, to the fullest extent permitted, excluded from this agreement. Nothing in this agreement excludes liability for death or personal injury, or for fraud or fraudulent misrepresentation. </li>
                    </ol>
                </li>
                <li data-level="13">
                    <h2 className="h">Termination</h2>
                    <ol className="l2">
                        <li>Without affecting any other rights available to it, either party may terminate this agreement with immediate effect by written notice to the other party if:
                            <ol className="l3">
                                <li>The School fails to pay for User Subscriptions as required by clause 3; </li>
                                <li>the other party fails to remedy a material breach of this agreement within 15 days of having been given written notice of its breach by the other party;</li>
                                <li>the other party becomes insolvent or enters into administration or some kind of winding up process; or</li>
                                <li>the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of its business.</li>
                            </ol>
                        </li>
                        <li>On termination of this agreement for any reason:
                            <ol className="l3">
                                <li>the relevant End Users’ accounts will be deleted;</li>
                                <li>upon deletion of an End User account, End User Data will be anonymised after one year and retained by Squirrel, and may be used to help improve GoApps in future and may feature in published outcomes of the project including marketing material;</li>
                                <li>upon written request by the School, Squirrel shall destroy all School Data and End User Data of the School’s End Users in its possession or control (except anonymised work referred to in clause 13.2(b)) except as required by law;</li>
                                <li>all licences granted under this agreement shall immediately terminate and each party shall return and make no further use of any equipment, property, Documentation, GoApps and other items (and all copies of them) belonging to the other party, subject to clause 13.2(b); </li>
                                <li>the School shall pay all reasonable expenses incurred by Squirrel responding to a School’s request in returning or disposing of School Data and End User Data;</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li data-level="14">
                    <h2 className="h">Miscellaneous</h2>
                    <ol className="l2">
                        <li>This agreement constitutes the entire agreement between the parties.</li>
                        <li>No variation of this agreement shall be effective unless it is in writing and signed by the parties’ authorised representatives.</li>
                        <li>Squirrel has no responsibility for any third-party website which may be made available via the GoApps.  </li>
                        <li>If any part of this agreement is unenforceable, it shall be deemed deleted, but that shall not affect the validity and enforceability of the rest of this agreement.</li>
                        <li>The School shall not, without the prior written consent of Squirrel, assign, transfer, all or any of its rights or obligations under this agreement. </li>
                        <li>Squirrel may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this agreement.</li>
                        <li>This agreement does not confer any rights on any person (other than the parties to this agreement and, where applicable, their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.</li>
                        <li>This agreement shall be governed by the law of England and Wales and the courts of England and Wales shall have exclusive jurisdiction.</li>
                    </ol>
                </li>
            </ol>
            <p>Should you have any further questions regarding these Terms and Conditions or the Privacy Policy, please contact using the link in the website footer.</p>
        </div>
    )
}

export default TermsPolicy