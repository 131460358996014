import React from "react";
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout';
import TermsPolicy from '../components/terms/terms-policy'

const TermsPage=({location}) => {
    
    return(
        <>
            <Helmet>
                <title>GoWrite&trade; Terms & Conditions</title>
            </Helmet>
            <Layout location={location}>
                <Container>
                    <Row className="justify-content-center">
                        <Col className="mt-3" lg={10} xl={9}>
                            <h1 className="heading mb-1">GoApps Terms &amp; Conditions</h1>
                            <p className="bodyText highlight mb-2">V2 - Last updated: August 2020</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="" lg={10} xl={9}>
                            <TermsPolicy cssClass="legal" />
                        </Col>
                    </Row>
                </Container>
            </Layout>
        </>
    )
}

export default TermsPage